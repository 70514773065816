.items-list {
  li {
    background-color: #ffffff;
    border: 1px solid rgba(212, 212, 212, 0.6);
    border-radius: 5px;
    transition: 0.2s ease-in;
  }
  .active {
    h4 {
      color: #022344;
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
.fresh-items-list {
  position: absolute;
  opacity: 0;
}
.fresh-items-list.scrolled {
  margin-top: 0;
  position: relative;
  opacity: 1;
}
.fresh-items-button {
  position: absolute;
  top: -13px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.25s;
  opacity: 1;
  &.fresh-items-button-hidden {
    opacity: 0;
    pointer-events: none;
  }
}
.items-list a:first-child li {
  margin-top: 0 !important;
}

.content-image {
  width: 114px;
  height: 85px;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #c4c4c4;
}

.content-new-image {
  width: 118px;
  height: 88px;
}

.content-new-title {
  width: 100%;
}

.hidden-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.hidden-one-line {
  -webkit-line-clamp: 1;
}

.hidden-two-lines {
  -webkit-line-clamp: 2;
}

.hidden-three-lines {
  -webkit-line-clamp: 3;
}

.content-title {
  width: 100%;
  .title-body {
    @extend .hidden-lines;
    -webkit-line-clamp: 2;
    color: rgba(0, 18, 43, 0.6);
    font-size: 0.9rem;
    &:hover {
      color: rgba(0, 18, 43, 0.6);
    }
  }
  .policymogul-keyword-match {
    background-color: transparent;
    font-weight: 700;
    color: rgba(0, 18, 43, 0.75) !important;
  }
}

.parliament-live-title {
  .title-body.title-h5 {
    font-size: unset;
  }
}

.news-title {
  line-height: 1.1;
  padding: 0;
  &::before {
    width: 0px;
  }
}

.main-title.news-title.content-item-title {
  -webkit-line-clamp: 3;
}

.nav-container {
  padding: 0.75rem 0;
  margin-right: -5px;
  margin-left: -5px;
  a {
    font-size: 0.9rem;
    color: #022344;
    &:hover {
      text-decoration: none;
    }
  }
}

.padding-to-nav {
  padding: 0 5px;
}

.content-nav-container {
  background: #f9f9f9;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.nav-arrow {
  border-radius: 8px;
  background-position: 0 4px;
  background-repeat: no-repeat;
  background-size: 17px auto;
  padding: 0.2rem 1rem 0.2rem 1.6rem;
  transition: 0.2s ease-in;
  font-weight: 700;
  &:hover {
    background-color: rgba($soft-grey, 0.16);
  }
}

.prev {
  @extend .nav-arrow;
  background-image: url('#{$cdn-url}arrow-left.svg');
}

.prev-active {
  background-image: url('#{$cdn-url}arrow-left.svg');
}

.next {
  @extend .nav-arrow;
  background-position: 95% 4px;
  padding: 0.2rem 1.6rem 0.2rem 1rem;
  background-image: url('#{$cdn-url}arrow-right.svg');
}

.next-disabled {
  background-image: url('#{$cdn-url}arrow-right.svg');
}

//STYLES FOR CONTENT
.key-update-content {
  .main-title {
    font-size: 1.25rem;
  }
  .heading-date {
    font-size: 0.92rem;
  }
}
.news-content-in {
  color: rgba($main-blue, 0.85);
  font-size: 16.4px;
  overflow-x: hidden;
  p {
    margin-bottom: 0.625rem;
  }

  a {
    color: #0094cc;
    &:hover {
      text-decoration: none;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #022344;
    font-weight: 700;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.04rem;
  }
  ul,
  ol {
    margin: 0px;
    padding: 0 0 0 1.21rem;
  }
}

.information-date {
  font-size: 1rem;
}

.library-material-content {
  iframe {
    width: 100%;
  }
  .embed-container {
    padding-bottom: 20px !important;
  }
  h2 {
    margin-top: 0.8em;
  }
}

.key-updates-container {
  overflow: hidden;
  position: relative;
}

.move-content {
  left: 0 !important;
}

.back-key {
  background: none;
  border: none;
  color: $hover-blue;
  text-align: left;
  font-size: 0.85em;
  cursor: pointer;
  &:focus {
    border: none;
    outline: none;
  }
}

.key-update-list {
  transform: translateX(0);
  transition: 0.2s ease-in;
}

.move-list {
  transform: translateX(-100%);
}

.main-update-content-container {
  .share-container-items {
    display: flex;
    button {
      display: block;
    }
  }
}

.share-container {
  display: none;
}

.share-links {
  position: absolute;
  right: 0;
  top: 24px;
  box-shadow: 0px 10px 20px rgba(33, 95, 175, 0.2);
  border-radius: 10px;
  background: #fff;
  font-size: 1rem;
  color: #787878;
  z-index: 100;
  button {
    color: #787878;
    min-width: 124px;
    text-align: left;
    position: relative;
    border-radius: 6px;
    padding: 0.15rem 0.5rem !important;
    transition: 0.2s ease-in;
    img {
      max-width: 15px;
      position: absolute;
      right: 7px;
      top: 4px;
    }
    svg {
      position: absolute;
      right: 5px;
      top: 4px;
    }
    &:focus {
      outline: none;
      border: none;
    }
    &:hover {
      background-color: rgba($soft-grey, 0.16) !important;
      transform: scale(0.95);
    }
  }
  .copied-image {
    max-width: 17px;
    top: 5px;
  }
}

.share-button {
  color: $hover-blue;
  padding: 0.15rem 0.7rem;
  margin: 0;
  font-size: 0.85rem;
  border-radius: 8px;
  transition: 0.2s ease-in;
  img {
    margin-top: -3px;
    max-width: 15px;
  }
  &:hover {
    background-color: rgba($soft-grey, 0.16);
  }
}

.share-button-active {
  background-color: rgba($soft-grey, 0.3);
  &:hover {
    background-color: rgba($soft-grey, 0.3);
  }
}

.close-item {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.scores {
  font-size: 0.825em;
  color: #848484;
}

.related-content {
  box-sizing: border-box;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 100%;
  padding-right: 8px;
  h3 {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 1em;
    &:not(:first-child) {
      margin-top: 1.65em;
    }
  }
}

.ScrollbarsCustom-Content {
  min-height: 95%;
}

.related-item {
  background: #fff;
  display: block;
  margin-bottom: 0.65em;
  padding: 0.5em;
  color: rgba(0, 18, 43, 0.8);
  border-radius: 5px;
  border: 1px solid rgba(212, 212, 212, 0.6);
  transition: 0.2s ease-in;
  position: relative;
  display: flex;
  align-items: center;
  p {
    font-size: 0.925em;
    line-height: 1.4;
    margin-bottom: 0;
  }

  .related-title {
    @extend .hidden-lines;
    -webkit-line-clamp: 3;
  }
  .related-date {
    color: rgba(0, 18, 43, 0.5);
    font-size: 0.9em;
    margin-top: 3px;
  }
  .delete-button {
    border-radius: 50% !important;
  }
  &:hover {
    text-decoration: none;
    .delete-button {
      display: block;
    }
  }
}

.related-item-container {
  &:hover {
    text-decoration: none;
    .delete-button {
      display: block;
    }
  }
}

//AE: TODO -> Create a SASS function for this

.related-key-updates,
.related-key-update {
  .icon-key-update {
    margin-right: 12px;
    margin-left: 3px;
  }
}

.related-consultations,
.related-consultation {
  .icon-consultation {
    margin-right: 12px;
    margin-left: 3px;
    font-size: 18px;
  }
}

.related-legislation {
  .icon-legislation {
    margin-right: 14px;
    margin-left: 4px;
    font-size: 18px;
  }
}

.related-briefing-material {
  .icon-briefing-material {
    margin-right: 15px;
    margin-left: 3px;
    font-size: 18px;
  }
}

.related-policy-ask,
.related-policy-asks {
  .icon-policy-ask {
    margin-right: 12px;
    margin-left: 3px;
    font-size: 18px;
  }
}

.related-q-and-as-,
.related-q-and-as-scotland-,
.related-q-and-as-wales-,
.related-q-and-as-ni-,
.related-official-report-scotland-,
.related-official-report-wales-,
.related-q-and-as-westminster-,
.related-hansard-updates,
.related-parliamentary-record,
.related-devolved-parliament-official-reports,
.related-senedd-committee-transcripts,
.related-edms,
.related-congressional-records {
  .icon-parliamentary-record {
    margin-right: 15px;
    margin-left: 3px;
    font-size: 16px;
  }
}

.related-library-material {
  .icon-library-material {
    margin-right: 12px;
    margin-left: 3px;
    font-size: 16px;
  }
}

.no-related-message {
  color: rgba(0, 18, 43, 0.6);
  .icon-warning-triangle {
    font-size: 13px;
    margin-right: 9px;
  }
}

.see-more-related {
  display: block;
  width: 100%;
  span {
    background: #fff;
  }
}

.hand-picked-item {
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-radius: 3px;
  display: inline-block;
  padding: 0.5em 1em 0.5em 2.05em;
  background: url('#{$cdn-url}hand-picked-icon.svg') 10px 9.5px / 12px auto no-repeat;
  margin-right: 0.35em;
}

.under-embargo-item {
  background: url('#{$cdn-url}clock-icon-red.svg') 10px 8.5px / 14px auto no-repeat;
  padding-left: 2.3em !important;
  span {
    color: #b40a37 !important;
  }
}

.under-embargo-list-item {
  span {
    color: #b40a37 !important;
  }

  .explanation-tag {
    max-width: none;
    &:hover {
      background-color: transparent;
    }
  }
}

.hover-stakeholder-image {
  background-position: center;
  background-size: cover;
  border-radius: 0px 5px 5px 0px;
}

.hover-stakeholder-message {
  min-width: 450px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  z-index: 10000;
  color: $main-blue;
  transition: 0.2s ease-in;
  .action-button {
    padding-top: 0.4em;
    padding-bottom: 0.5em;
  }
}

.hover-stakeholder-link {
  color: $light-blue;
  cursor: pointer !important;
  &:hover {
    color: $light-blue !important;
    text-decoration: underline !important;
  }
}

.navigation-buttons-content {
  border-top: 1px solid rgba($main-grey, 0.6);
  .navigation-button {
    color: rgba($main-blue, 0.7);
    padding: 9px 0 11px 0;
    position: relative;
    display: flex;
    align-items: center;
    &:hover {
      color: $main-blue;
      text-decoration: none;
    }
  }
  .navigation-button.disabled {
    pointer-events: none;
  }
  .icon-right-triangle-filled,
  .icon-left-triangle-filled {
    font-size: 9px;
    display: block;
    padding-top: 3px;
  }
  .icon-right-triangle-filled {
    margin-left: 11px;
    margin-right: -2px;
  }
  .icon-left-triangle-filled {
    margin-right: 11px;
    margin-left: -2px;
  }
}

.content-border-title {
  border-bottom: 0.5px solid #d9d9d9;
}

.content-title-with-summary {
  padding: 1rem;
  @media screen and (min-width: 992px) {
    padding: 1.5rem;
  }
}
.content-title-in-widget {
  margin-left: 1rem;
  padding-right: 1rem;
  @media screen and (min-width: 992px) {
    margin-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.summary-content {
  transition: all 0.3s;
  color: rgba($main-blue, 0.78) !important;
  border-bottom: 0.5px solid #d9d9d9;
  & strong,
  b,
  .open,
  li::marker {
    color: $main-blue;
  }
  ul {
    padding-left: 18px;
  }
  .dropdown-item-element {
    &::after {
      top: -3px;
    }
  }

  .summary-title-section {
    user-select: none;
    .actions-container {
      gap: 4px;
      .dropdown-item-element {
        padding-right: 16px;
      }
    }
  }

  .tailor-summaries-btn {
    &:hover {
      background-color: #00122b;
      color: #fff;
    }
  }
  .edit-commentary-btn {
    &:disabled {
      cursor: default !important;
      &:hover {
        background-color: transparent !important;
      }
      & span {
        cursor: default !important;
        opacity: 0.7;
      }
    }
  }

  .item-actions-box {
    min-width: 360px;
    right: 7px;
    .share-container-items {
      button {
        padding: 4px 16px 11px 16px !important;
        &:hover {
          background-color: rgba(212, 212, 212, 0.5) !important;
        }
        a {
          margin: 0 !important;
          color: inherit;
          &:hover {
            text-decoration: none;
            color: inherit !important;
          }
        }
      }
    }
    .icon-communication {
      font-size: 25px;
      transform: translateY(6px);
      display: inline-block;
      margin-right: 14px;
    }
    .icon-bag {
      font-size: 25px;
      transform: translateY(5px);
      display: inline-block;
      margin-right: 14px;
      font-weight: 700;
    }

    .item-actions-box-description {
      white-space: normal;
      margin-bottom: 0;
      border-top: 1px solid rgba(212, 212, 212, 0.6);
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      cursor: default;
      color: rgba(0, 18, 43, 0.7);
    }
  }
}
.summary-content.border-dashed {
  border: 0.5px solid #ececec;
  border-left: 0px transparent;
  border-right: 0px transparent;
}
.summary-content.summary-content-collapsed {
  &:hover {
    background-color: rgba(0, 148, 204, 0.07);
  }
}

.summary-edit-banner {
  padding: 13px 26px 13px 20px;
  border-radius: 3px;
  border: 1px solid #00122b;
  background: #fff;
  color: #00122b;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
}
.summary-not-tailored-banner {
  @extend .summary-edit-banner;
  margin-top: 12px;
  margin-bottom: 30px;
}

.summary-content-dropdown-component-container {
  overflow: hidden;
  white-space: nowrap;
  .tailor-summaries-dropdown-component {
    max-width: calc(100% - 80px);
  }
  .title-max-width {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 35px;
  }
  .not-tailored-title {
    display: flex;
    display: inline-flex;
    overflow: hidden;
    width: 100%;
  }
  & > b:has(.blue-text) {
    color: #0094cc;
    font-weight: 400 !important;
  }
}

.summary-content-dropdown-component-container.agency-user-summary {
  max-width: calc(100% - 160px);
  overflow: unset !important;
}

.summary-content-dropdown-component-container.agency-user-summary.not-tailored {
  max-width: calc(100% - 280px);
}
.border-dashed {
  border-style: dashed !important;
}

.tailor-summaries-dropdown-component {
  max-width: calc(100% - 240px);
  display: inline-block;
  .disabled-hour-select {
    opacity: 0.8;
    background-color: transparent !important;
    &:hover {
      text-decoration: none !important;
      opacity: 0.7;
    }
  }
  .hour-select {
    border: none;
    padding-left: 4px !important;
    min-width: 0;
    font-weight: inherit;
    &:hover {
      text-decoration: underline;
      opacity: 0.8;
    }
    & > span {
      margin-right: 15px;
      text-overflow: ellipsis;
      display: block;
    }
  }
  .hidden-lines.hidden-one-line {
    margin-right: 12px;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
  }
  & .frequency-dropdown {
    width: 100%;
    min-width: 300px;
    border-radius: 20px;
    box-shadow:
      0 1px 3px rgba(60, 63, 66, 0.32),
      0 4px 10px rgba(60, 63, 66, 0.15);
    & .icon-square-plus {
      margin-left: 2px;
      font-size: 18px;
    }
    & .add-keyword-list-button {
      border-radius: 0 0 20px 20px;
      padding: 12px 12px 16px 12px;
    }
    & .ScrollbarsCustom-Track {
      padding-top: 10px;
    }
  }
}

.summary-waiting {
  display: flex;
  opacity: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  .waiting-bar {
    width: 100%;
    max-width: 100%;
  }
}

.ai-summary-content {
  opacity: 0;
}

.remaining-credits {
  .icon-info {
    display: inline-block;
    top: -1px;
    vertical-align: middle;
  }
}

.tailor-summary-action-container {
  .flex-centered.pt-1 {
    .update-button {
      max-width: 220px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .update-button.wide {
      max-width: 400px;
    }
  }
}

.relevant-extract-keyupdates {
  overflow: hidden;

  .title-body-transition {
    transition-duration: 0.5s;
    transition-property: transform;
  }

  .title-body-before {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      height: 10px;
      width: 100%;
      bottom: -10px;
      background-color: #fff;
    }
  }

  .title-body-animated {
    background: -webkit-gradient(linear, left top, right top, from(#e0dede), to(#e0dede), color-stop(0.5, #00122b));
    background-size: 110px 100%;
    background-clip: text;
    animation-name: shimmer;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    background-repeat: no-repeat;
    background-position: 0 -110px;
    background-color: #e0dede;
  }
}

.parliament-live-item-subject {
  max-width: 175px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

.graph-skeleton {
  display: flex;
  align-items: center;

  & > div {
    width: 100%;
    position: relative;
    .graph-skeleton-line-before {
      position: absolute;
      left: 0;
      top: -2px;
    }
    &:nth-child(1) {
      transform: rotate(-30deg);
      margin-left: -5px;
    }
    &:nth-child(2) {
      transform: rotate(30deg);
      margin-left: -16px;
    }
    &:nth-child(3) {
      transform: rotate(-30deg);
      margin-left: -5px;
    }
    &:nth-child(4) {
      transform: rotate(30deg);
      margin-left: -16px;
    }
    &:nth-child(5) {
      transform: rotate(-30deg);
      margin-left: -5px;
    }
  }
}

@media screen and (max-width: 992px) {
  .tailor-summary-action-container {
    flex-direction: column !important;
    gap: 10px;
    & > p {
      width: 100%;
    }
    .flex-centered.pt-1 {
      flex-wrap: wrap;
      gap: 10px;
      justify-content: end;
      width: 100%;
      .update-button {
        max-width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .summary-content:not(.summary-content-collapsed):not(.summary-content-loading) {
    .summary-not-tailored-banner {
      margin-top: 0;
    }
    .summary-header-container {
      flex-direction: column-reverse;
      .actions-container {
        align-self: flex-end;
      }
    }
  }
  .summary-header-container {
    .summary-content-dropdown-component-container.agency-user-summary {
      align-self: flex-start;
      max-width: calc(100% - 40px);
      .tailor-summaries-dropdown-component {
        & .frequency-dropdown {
          left: -130px;
        }
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .tailor-summary-action-container {
    .flex-centered.pt-1 {
      button {
        width: 100%;
      }
      button.active-outlined-button {
        margin-right: 0 !important;
      }
    }
  }
  .tailor-summaries-dropdown-component {
    & .frequency-dropdown {
      left: calc(-100% - 119px);
    }
  }
}

@media screen and (min-width: 408px) {
  .under-embargo-item {
    background-position: 10px 9.7px !important;
  }
  .hand-picked-item {
    background-position: 10px 10.5px;
  }
}
@media screen and (min-width: 420px) {
  .share-container {
    display: block;
  }
}

@media screen and (max-width: 420px) {
  .main-update-content-container {
    .share-container-items {
      display: block;
    }
  }

  .summary-content {
    .item-actions-box {
      width: 360px;
      right: -30px;
    }
  }
}

@media screen and (min-width: 526px) {
  .content-new-title {
    width: calc(100% - 120px);
  }
  .content-title {
    width: calc(100% - 130px);
  }
}

//ACTIONS WHEN HOVER ITEMS
@media screen and (min-width: 992px) and (hover: hover) {
  .item-list-hoveractions {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
  }

  .influence-list-item,
  .saved-item-element {
    &:hover {
      @extend .item-list-hoveractions;
    }
  }

  .items-list {
    li {
      &:hover {
        @extend .item-list-hoveractions;
      }
    }
  }

  .open-consultations-item {
    .collapsed {
      &:hover {
        @extend .item-list-hoveractions;
      }
    }
  }

  .tweet-container {
    &:hover {
      @extend .item-list-hoveractions;
    }
  }
}

@media screen and (min-width: 992px) {
  .items-list a:first-child li,
  .items-list .influence-list-item:first-child {
    margin-top: 0.5em !important;
  }
  //AE: Remove marging when there is the backpopulated banner
  .item-list-backpouplated a:first-child li,
  .item-list-backpouplated a:first-child .open-consultations-item,
  .item-list-backpouplated li:first-child {
    margin-top: 0em !important;
  }

  .stakeholder-list-content-scrollbar {
    margin-top: 0.5em;
  }
  .stakeholder-items-list a:first-child li {
    margin-top: 0em !important;
  }

  .items-list a:first-child li .stake-information-bio-list {
    margin-top: 0 !important;
  }

  .key-update-content {
    max-width: 1070px;
    margin: 0 auto;
    padding: 0 10px;
    .main-title {
      font-size: 1.35rem;
    }
  }
  .related-item {
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
      color: rgba(0, 18, 43, 0.8);
    }
  }
}

@media screen and (min-width: 1280px) {
  .hover-container {
    visibility: hidden;
    &:hover {
      visibility: visible;
    }
    &:focus {
      visibility: visible;
    }
  }

  .hover-content {
    visibility: visible;
  }
}
