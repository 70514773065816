.content-item {
  padding: 0 0em 0 0.5em !important;
  position: relative;
}

.content-item-link {
  position: relative;
  display: block;
  border: none;
  outline: none;
  &:not(.influence-list-item) {
    &:focus {
      border: none;
    }
  }
  &:focus {
    outline: none;
  }
  .button-thumbs-down {
    margin-right: 4px;
  }
}

.content-item-title {
  color: #022344;
  font-size: 0.95rem;
  font-weight: 600;
  @extend .hidden-lines;
  -webkit-line-clamp: 3;
}

.stakeholder-list-item {
  padding-left: 0em !important;
}

.relevant-remark-item {
  padding-left: 2.3em !important;
  padding-right: 2.3em !important;
  .title-body {
    &::before,
    &:after {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
    }
    &::before {
      left: 28px;
      background: url('#{$cdn-url}quote_open_black.png') center / contain no-repeat;
    }
    &::after {
      right: 30px;
      background: url('#{$cdn-url}quote_close_black.png') center / contain no-repeat;
    }
  }
}
