@import './analytics-embed';
$box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
.embed-header {
  height: 60px;
  background: #00122b;
}
.embed-main-header {
  padding: 2.5em 2em 1.5em 2.5em !important;
  .main-title {
    font-size: 1.1875rem;
  }
}

.embed-content {
  background: #ffffff;
  border: 1px solid rgba(212, 212, 212, 0.5);
  border-radius: 8px;
  margin: 3px;
  /* max-width: 600px;
   */
}

.embed-padding {
  padding-left: 1em;
  padding-right: 1em;
}

.main-description {
  line-height: 1.4;
  .main-description-text {
    opacity: 0.65;
  }
  .dropdown-item-element {
    opacity: 0.9;
  }
}

.main-description-white {
  color: rgba(255, 255, 255, 0.5);
  span {
    color: rgba(255, 255, 255, 0.7);
  }
}

.menu-embed-container {
  width: 100%;
}

.menu-embed-items {
  background: #f9f9f9;
  padding: 0.8em 0.5em;
  border-top: 1px solid rgba(212, 212, 212, 0.5);
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  .policy-list {
    flex-wrap: nowrap;
    white-space: nowrap;
    transition: 0.2s ease-in;
  }
  button {
    font-size: 0.75em;
    font-weight: 700;
    text-transform: uppercase;
    opacity: 0.6;
    transition: 0.2s ease-in;
    color: currentColor;
    &:hover {
      opacity: 1;
    }
  }
  li {
    .active {
      opacity: 1;
    }
  }
}

.menu-overflowcontainer {
  width: 100%;
  overflow: hidden;
}

.embed-menu-arrow {
  width: 24px;
  height: 24px;
  overflow: hidden;
  text-indent: -1000px;
  left: 0;
  margin-top: 0;
  top: 2px;
  z-index: 100;
  &:before {
    content: '';
    width: 12px;
    height: 12px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 8px solid #00122b;
    opacity: 0.85;
    left: 0px;
    margin-top: -1px;
    margin-left: 7px;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
  }
}

.embed-menu-arrow-right {
  &:before {
    border-right: 0;
    border-left: 9px solid #00122b;
  }
}

.embed-link {
  color: rgba($main-blue, 0.85);
  &:hover {
    color: rgba($main-blue, 0.85);
    text-decoration: none;
  }
  span {
    display: inline-block;
    width: 86px;
    height: 17px;
    vertical-align: middle;
    margin-top: -2px;
    margin-left: 3px;
    overflow: hidden;
    background: url('#{$cdn-url}logo.svg') center/contain no-repeat;
  }
}

.embed-widget-body {
  .intercom-app,
  .intercom-lightweight-app {
    display: none;
  }
}

.embed-button-back {
  color: #0094cc !important;
  position: relative;
  opacity: 1 !important;
  padding-left: 16px;
  &::before {
    content: '';
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 6px solid #0094cc;
    position: absolute;
    top: 4px;
    left: 0px;
  }
  &:hover {
    color: rgba(0, 148, 204, 0.8) !important;
    &::before {
      border-right: 6px solid rgba(0, 148, 204, 0.8);
    }
  }
}

.embed-content {
  .items-list {
    li {
      &:hover {
        box-shadow: $box-shadow;
      }
    }
  }
  .open-consultations-item .collapsed {
    &:hover {
      box-shadow: $box-shadow;
    }
  }
  .tweet-container {
    &:hover {
      box-shadow: $box-shadow;
    }
  }
  .share-image {
    &:hover {
      background-color: rgba(0, 148, 204, 0.08) !important;
    }
  }
}

.no-select-button {
  user-select: none;
}

.feeds-no-available {
  color: rgba(0, 18, 43, 0.7);
  padding-top: 80px;
  p {
    max-width: 334px;
    margin: 0 auto;
  }
  a {
    color: #0094cc;
    &:hover {
      color: #0094cc;
      text-decoration: none;
    }
  }
}

.embed-link-bottom {
  padding-top: 14px;
  padding-bottom: 13px;
  border-top: 1px solid rgba(#d4d4d4, 0.5);
  border-radius: 0 0 8px 8px;
  position: relative;
  z-index: 200;
  background-color: #f9f9f9;
  font-size: 14px;
  padding-right: 1.8em;
}

.embed-menu-swiper {
  padding: 0 0.85em;
  .swiper-wrapper {
    //width: 300px;
  }

  .swiper-slide {
    text-align: left;
    padding-left: 15px;
    &:not(:last-child) {
      padding-right: 15px !important;
    }
  }

  /*.swiper-slide.slide-menu-parliamentaryrecord {
    margin-right: 5px !important;
  }*/

  .swiper-button-prev,
  .swiper-button-next {
    @extend .embed-menu-arrow;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: $main-blue;
    &:before {
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      opacity: 1;
    }
  }

  .swiper-button-prev {
    &::before {
      margin-left: -7px;
      border-right: 6px solid #fff;
    }
  }

  .swiper-button-next {
    @extend .embed-menu-arrow-right;
    left: calc(100% - 1.5em);
    &::before {
      border-left: 6px solid #fff;
      margin-left: 7px;
    }
  }

  .swiper-button-disabled {
    display: none;
  }
}

$slide-menu-sizes: (
  'policyasks': 77,
  'briefingmaterial': 120,
  'lobbyingmaterial': 121,
  'keyupdates': 78,
  'consultations': 96,
  'legislation': 75,
  'parliamentaryrecord': 150,
  'parliamentlive': 96,
  'commonslibraryarticles': 111,
  'events': 114,
  'tweets': 47,
);

@each $name, $menu-size in $slide-menu-sizes {
  $result: $menu-size + 40;
  .slide-menu-#{$name} {
    width: $result + px !important;
  }
}

.embed-back-container {
  padding-left: 2.5em;
}

.embed-content {
  .main-content-scrollbar {
    &:hover {
      .ScrollbarsCustom-TrackY {
        opacity: 1;
      }
    }
    .ScrollbarsCustom-ThumbY {
      &:hover {
        background-color: #99a4ae !important;
      }
    }
    .ScrollbarsCustom-TrackY {
      opacity: 0;
      right: 12px !important;
    }
    .ScrollbarsCustom-Content {
      padding-right: 1.2em !important;
      padding-left: 0.25em !important;
    }
  }
  .interest-by-region-inner-container {
    .ScrollbarsCustom-Content {
      padding: 0 !important;
    }
  }
}

.skeleton-embed-container {
  padding-right: 1.95em;
  padding-left: 1.95em;
}

.embed-analytics-widget-title {
  user-select: none;
}

.embed-content-single {
  .embed-padding {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
  .embed-analytics-widget-title {
    padding-left: 0.5em !important;
    font-size: 1.15em !important;
  }
  .embed-analytics-topic-picker {
    padding-top: 1.2em !important;
    padding-bottom: 1.2em !important;
  }
}

.embed-content-container {
  .share-icon-button {
    font-size: 0.85em;
    color: #848484;
  }
  .icon-share {
    font-size: 12px;
  }
}

@media screen and (min-width: 550px) {
  .embed-menu-arrow {
    &::before {
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 6px solid #fff;
      margin-left: 0;
      margin-top: -2px;
    }
  }
  .embed-menu-arrow-right {
    &:before {
      margin-left: 5px;
      border-right: 0;
      border-left: 6px solid #fff;
    }
  }
  .embed-menu-swiper {
    .swiper-button-prev {
      &::before {
        margin-left: -7px;
      }
    }
    .swiper-button-next {
      &::before {
        margin-left: 7px;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .embed-padding {
    padding-left: 2em;
    padding-right: 2em;
  }

  .website-feeds-popup-embed {
    .main-content-scrollbar {
      width: calc(100% + 1rem) !important;
      .ScrollbarsCustom-TrackY {
        right: 0px !important;
      }
    }
    .embed-content-container {
    }
    .embed-padding {
      padding-left: 1em;
      padding-right: 1em;
    }

    .embed-link {
      margin-right: 16px;
    }
    .related-content-column {
      display: none;
    }
    .general-content-column {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .leaflet-bottom {
      bottom: 22px;
    }
    .embed-analytics-topic-picker {
      .dropdown-keywordlist {
        padding: 0.75em 1em;
        font-size: 19px;
      }
    }
  }
}
